/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;1,500&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

textarea:focus,
input:focus {
  outline: none;
}

/* TODO: Move it to more appropriate page */
/* WYWSGI Editor's CSS */
.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: black;
}
.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0px;
}

[type="checkbox"]:checked:disabled {
  background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27%23121212%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e") !important;
  opacity: 0.5;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27%23121212%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e") !important;
}

div[id^="cell-"] > div {
  overflow: visible !important;
}


.m-t-overflow > div:nth-child(2) > div {
  overflow: visible;
}

.table-overflow {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  padding-bottom: 4rem;
}

.rdt_Pagination select + svg {
  display: none !important;
}

/* Custom Scrollbar */

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f4f4f4;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #b7b7b7;
  border-radius: 10px;
  border: 2px solid #f4f4f4;
}

.sort-icon{
  width: 24px;
  height: 16px;
  padding-left: 8px;
}